/* src/components/schedule3/MatchSchedule.css */

/* Reset and Root Styles */
.match-schedule-root html, 
.match-schedule-root body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

.match-schedule-root {
  display: flex;
  height:calc(100% + 16px);
  width: calc(100% + 32px);
  margin-left:-16px;
  margin-bottom:-16px;
  overflow: hidden;
}

/* Crew Column Styles */
.match-schedule-column.crew {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px;
  width: 24%; /* Fixed width */
  box-sizing: border-box;
  overflow-y: auto; 
  height: 100vh; /* Full height */
  
}

/* Schedule Columns Container */
.match-schedule-columns {
  display: flex;
  flex: 1;
  padding: 15px;
  box-sizing: border-box;
  overflow-x: auto;
  height: 100vh;
  
  /* gap automatically distributes space between flex items */
  gap: 10px;
  margin:0;
}
.match-schedule-column.schedule.collapsed {
    flex: 0 0 30px !important;       /* enough width to see the icon */
    width: 30px !important;
    min-width: 30px !important;
  padding: 0 !important;
  margin-right: 0 !important;
  overflow: hidden;
}

/* Individual Schedule Column Styles */
.match-schedule-column.schedule {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.2s ease;
  background-color:#B3CDE6;
  /* Remove the fixed width and padding from here:
  flex: 0 0 300px;
  padding: 15px; 
  */

  /* Use flex: 1 to allow columns to share the space equally. */
  flex: 1; 

  /* (Optional) If you want a minimum width so they don't get too skinny:
     flex: 1 1 200px; 
     or 
     min-width: 200px;
  */

  /* keep if you still want spacing between columns */
  box-sizing: border-box;
  overflow-y: auto;
  height: calc(100vh - 30px);
  border-radius: 5px;
  padding:15px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}


/* Title Styles */
.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px; /* Spacing below title */
}

/* Draggable Crew List Styles */
.draggable-crew-list {
  flex: 1 1 auto;
  min-height: 60px; 
  box-sizing: border-box;
  overflow: hidden; 
  padding: 10px;
  border-radius: 5px;
  cursor: grab;
  transition: background-color 0.2s ease;
}

.draggable-crew-list:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

/* Droppable Job Styles */
.droppable-job {
  /* Styles are handled inline in the component */
  /* Optionally, add transition for smoother visual feedback */
  transition: background-color 0.2s ease, opacity 0.2s ease;
}

/* Scrollbar Styling (Optional) */
.match-schedule-column.crew::-webkit-scrollbar,
.match-schedule-columns::-webkit-scrollbar,
.match-schedule-column.schedule::-webkit-scrollbar {
  width: 8px;
}

.match-schedule-column.crew::-webkit-scrollbar-thumb,
.match-schedule-columns::-webkit-scrollbar-thumb,
.match-schedule-column.schedule::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.match-schedule-column.crew::-webkit-scrollbar-track,
.match-schedule-columns::-webkit-scrollbar-track,
.match-schedule-column.schedule::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
